@mixin admin-table {
  $dark-theme-blue: #00232e;
  $dark-theme-blue-header: hsl(191, 9%, 70%);

  .admin-table-container {
    background-color: #333333;
  }

  .sm-body {
    background-color: #3c464b;

    .content {
      background-color: #3c464b;
    }
  }

  .admin-table {
    .mat-mdc-header-row {
      background-color: var(--lightgrey200);

      .mat-mdc-header-cell {
        border-bottom: none;

        .checkbox {
          .outer-circle {
            border-color: var(--ion-color-primary) !important;
          }

          .checked-circle {
            background-color: var(--ion-color-primary) !important;
          }
        }
      }

      p {
        color: black;
        border-right: 1px solid rgba(#00232e, 0.3);
      }
    }

    .appliedFilter {
      background-color: #9bafbe;
    }

    .headerTabSelected {
      background-color: var(--lightgrey100);
    }

    .add-row-style {
      background-color: var(--lightgrey100);
    }

    .edit-button-so-table {
      background-color: white;
      color: var(--ion-color-primary);
    }

    .mat-mdc-row {
      border-color: #3a4449;
    }

    .mat-mdc-row:hover,
    .mat-row.row-higlight {
      .edit-button-so-table {
        background-color: var(--ion-color-primary);
        color: white;
      }
    }

    // BEGIN row colors

    .mat-mdc-row {
      background-color: #555f69;

      .mat-mdc-cell.column {
        &-name,
        &-updatedby {
          background-color: #788791;
        }

        &-createdby,
        &-created,
        &-assignedto {
          background-color: #697882;
        }

        &-createdby,
        &-created {
          p {
            border-right: 1px solid #b0bdc5;
          }
        }
      }

      &:hover {
        background-color: #6e7d87 !important;
        .mat-mdc-cell.column {
          &-name,
          &-updatedby {
            background-color: #9fb7c6;
          }

          &-createdby,
          &-created,
          &-assignedto {
            background-color: #849dae;
          }

          &-updatedby i {
            color: var(--ion-color-primary);
          }
        }

        p {
          color: white;
        }
        &.column-name,
        &.column-updatedby {
          p {
            color: black;
          }
        }
      }

      &.row-higlight {
        background-color: #788791;
        .mat-mdc-cell.column {
          &-name,
          &-updatedby {
            background-color: var(--lightgrey200);

            p {
              color: black;
            }
          }

          &-createdby,
          &-created,
          &-assignedto {
            background-color: #9bafbe;
          }

          &-updatedby i {
            color: var(--ion-color-primary);
          }
        }

        p {
          color: white;
        }
        &.column-name,
        &.column-updatedby {
          p {
            color: black;
          }
        }
      }
    }

    // END row colors

    .ps {
      color: white;

      &--Population {
        background-color: #00465f;
      }
      &--budgetCalculation {
        background-color: #006384;
      }
      &--budgetAllocation {
        background-color: #1e8e92;
      }
      &--letterCreation {
        background-color: #42a9a9;
      }
      &--payrollExport {
        background-color: var(--lightgrey100);
        color: black !important;
      }
      &--finalized {
        background-color: #ccd9e5;
        color: black !important;
      }
    }

    .si {
      &--createdby {
        color: white;
      }
    }

    .low-light {
      background-color: #e2e9ef;
    }

    .info-icon {
      color: white;
    }

    .checkbox {
      .outer-circle {
        border-color: white !important;
      }

      .checked-circle {
        background-color: white !important;
      }
    }
  }
}
