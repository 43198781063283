@use '../darkmode/admin-table';

body {
  &.dark-mode {
    @include admin-table.admin-table;

    app-season-overview {
      background: linear-gradient(to bottom, black, #00242f) !important;
    }

    .selection-item {
      box-shadow: 1px -3px 5px var(--ion-box-shadow-default-color) !important;
    }

    .sortby-menu-item {
      background-color: rgb(45, 45, 45) !important;
    }

    .mat-mdc-input-element {
      // color: white !important;
    }

    .edit-part {
      background-color: #555f69 !important;
    }

    .white-input,
    .white-span-perc {
      color: white;
    }
    app-rule {
      .mat-mdc-input-element {
        color: inherit !important;
      }
    }

    .mat-badge-content {
      color: #2d2750 !important;
      background: white !important;
    }

    app-communication {
      background-color: #3c464b;
      p {
        color: black;
      }
      .header-column {
        p {
          color: white !important;
        }
      }
    }

    .mat-mdc-cell.column-title p {
      color: black !important;
    }

    .dialog-text-body p,
    .mat-mdc-select-value p,
    .mat-option-text p {
      color: black !important;
    }

    .column-updated {
      p,
      i {
        color: black !important;
      }
    }

    ion-content {
      --ion-background-color: rgb(45, 45, 45) !important;
    }

    .cat-items {
      background-color: transparent;
    }

    .logo-wrapper {
      &:after {
        background-image: url(/assets/svgs/SE_Logo_White_RGB.svg) !important;
        border: none !important;
      }
    }

    .text-part {
      h1,
      p {
        color: white !important;
      }
    }

    h1,
    h2,
    h4,
    p,
    ion-button .headline-fy,
    .no-topics,
    ion-icon {
      color: white;
    }

    .custom-header {
      h4,
      p,
      .m-icon-label,
      mat-icon,
      .material-icons-outlined {
        color: white !important;
      }

      ion-avatar {
        border: solid 2px white !important;
      }

      .profile-info > p {
        &:after {
          color: white !important;
        }
      }
    }

    app-topic-item,
    app-tree-box .top {
      p,
      h3,
      .more-details {
        color: white !important;
      }
    }

    .dashed-border-part {
      border-color: white !important;
    }

    .dots:before {
      border-color: white !important;
    }

    .lay-wrapper--header {
      background-color: black;
      -moz-box-shadow: inset 0 -50px 90px -80px lightgray;
      -webkit-box-shadow: inset 0 -50px 90px -80px lightgray;
      box-shadow: inset 0 -50px 90px -80px lightgray;
    }

    // background of lists, containers etc.
    app-topic-list,
    app-adjust-subview-calc > .container > .right {
      background: rgb(45, 45, 45) !important;
    }

    app-topic-list > .overlay-fade {
      box-shadow: 0px 5px 10px 15px rgb(45, 45, 45);
    }

    // background of items in dark lists, containers etc.
    app-topic-item,
    .budget-item,
    .simplebar-vertical,
    .simplebar-horizontal,
    app-tree-box .top,
    app-tree-box .bottom,
    app-adjustment-section-box .container {
      background: rgb(84, 84, 85) !important;
    }

    .simplebar-scrollbar::before {
      background: white !important;
    }

    app-tree-box .person-container:not(.children-available) .toggle-children {
      background: gray !important;
    }

    .season-container {
      background: linear-gradient(to bottom, black, rgb(0, 36, 47)) !important;
    }

    .month-text,
    .week-text {
      background-color: black !important;

      &.active {
        color: black !important;
        background-color: rgb(255, 255, 255) !important;
      }
    }

    .front,
    .menu-pointer,
    .sidemenuitem {
      background-color: #252525;
    }

    .sub-menu-item:hover {
      background-color: #505050;
    }

    .menu-list {
      li {
        background-color: transparent;
        color: white;
      }
      li:hover,
      .highlight-li {
        background-color: black !important;
      }
    }

    .sm-header {
      background-image: linear-gradient(to bottom, #0f4760, #000000) !important;
    }

    .create-season-steps {
      background-image: linear-gradient(to bottom, #0f4760, #000000) !important;
    }

    .create-season {
      &--left {
        p {
          // color: white !important;
        }
        background-color: var(--darkgrey500);
      }
      &--right {
        background-color: #454f55;
      }
    }

    app-population .content-row p,
    .entries-count p,
    app-simulation-management .content-row p {
      color: black !important;
    }

    app-settings-distribution {
      .inner-content {
        background-color: var(--darkgrey300);
      }
      .header-column {
        background-color: var(--lightgrey200) !important;
      }
      .content-row p {
        color: black;
      }
    }

    app-basic-table {
      .header-row {
        background-color: var(--lightgrey200) !important;
      }
      .content-row p {
        color: black;
      }
      input,
      .white-span-perc {
        color: black !important;
      }
    }

    .create-season-bottom {
      background-color: #343f45;
    }

    .rule-engine-outer {
      background-color: #3a3a3a;
    }

    .rule-engine {
      // background-color: var(--darkgrey500) !important;
      h3 {
        color: white;
      }
    }

    .population {
      background-color: #454f55 !important;
    }

    .top-frame {
      background-color: #09131b;
    }

    .single-step p {
      color: black;
    }

    app-add-simulation,
    .uc-dialog {
      p {
        color: black;
      }
    }

    .button-container {
      background-color: #2d2d2d !important;
    }

    app-master-data,
    app-user-list,
    app-role-mgmt {
      .content-row p {
        color: black;
      }
    }

    app-translation-view {
      h4,
      p {
        color: black;
      }
    }

    app-communication {
      .container {
        .header-column {
          p {
            color: black !important;
          }
        }
      }
    }

    .mat-mdc-checkbox,
    .view-popuplation-preperation {
      p {
        color: black;
      }
    }

    app-employee-mgmt,
    app-topnode-mgmt,
    app-rule-sets-mgmt,
    app-user-list {
      .container {
        background: #3c464b;
      }
    }

    .org-geo-cluster-holder,
    .role-list {
      background: #3c464b;
    }

    .header-row {
      background-color: var(--lightgrey200);
      p {
        color: black !important;
      }
    }
    .header-column {
      background-color: var(--lightgrey200) !important;
      p {
        color: black !important;
      }
    }
    .cr--first {
      background-color: #788791 !important;
      p {
        color: white;
      }
    }
    .cr--second {
      background-color: #555f69 !important;
      p {
        color: white;
      }
    }

    .population h3 {
      color: white;
    }

    .rule-engine-table {
      background-color: #09131b;
    }

    .rule-setup {
      background-color: #3c464b;
    }

    .valueDataItem .content-row {
      background-color: #555f69;
      p {
        border-right: 0px;
      }
      &.other-row-color {
        background-color: #788791;
        p {
          border-right: 1px solid var(--lightgrey200);
        }
      }
    }
  }
}
