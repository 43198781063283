/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use './styles/siemens-energy' as se-colors;

@use 'simplebar/dist/simplebar.css';
@use '@angular/material/prebuilt-themes/indigo-pink.css';

/* Structured Packages */
@use './theme/theme-variables';
@use './styles/_global/normalize';
@use './styles/_global/queries';
@use './styles/_global/mixins';
@use './styles/_global/typography';
@use './styles/_global/base';
@use './styles/_global/darkmode';
@use './styles/_global/matslider';
@use './styles/layouts/lay-wrapper';
@use './styles/atoms/a-text';
@use './styles/components/simple-table';

/* To add more visual modifications use this SCSS below */
@use './styles/_global/additionalmods';

/* Add Global Organizational Stylings here */
@use './styles/_organizational-packages/siemens-energy';
@use 'input-base';

@use 'ngx-toastr/toastr';
// @use '~@ng-select/ng-select/themes/default.theme.css';
