@use './typography';

html {
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

body {
  width: 100%;
  min-width: 768px;
  min-height: 100%;
  margin: 0;
  padding: 0;

  &.dark {
    background: var(--lightgrey100);
  }

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul,
li {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

ul {
  list-style: none;

  li {
    @include typography.copy-txt(var(--darkgrey900));
  }
}

ol {
  margin: 0;
  padding: 0;

  li {
    @include typography.copy-txt(var(--darkgrey900));
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

menu {
  padding: 0;
  margin: 0;
}

button {
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type='number'] {
  appearance: none;
  margin: 0;
}
